/**
 * phone
 * 电话
 */

import request from '@/utils/request'

// 展示
export const phoneList = (data) => {
    return request({
        method: 'post',
        url: '/phone/list',
        data
    })
}

// 查询全局号码
export const findOne_all = (data) => {
    return request({
        method: 'post',
        url: '/phone/findOne_all',
        data
    })
}

// 
export const phoneDaystatusAll = (data) => {
    return request({
        method: 'post',
        url: '/phone/daystatusAll',
        data
    })
}
// 添加
export const phoneAdd = (data) => {
    return request({
        method: 'post',
        url: '/phone/add',
        data
    })
}
// 查找用户
export const phoneFindUser = (data) => {
    return request({
        method: 'post',
        url: '/phone/find-user',
        data
    })
}
// 回收
export const phoneRecycle = (data) => {
    return request({
        method: 'post',
        url: '/phone/recycle',
        data
    })
}
// 回收还原
export const phoneRecycleBin = (data) => {
    return request({
        method: 'post',
        url: '/phone/recycleBin',
        data
    })
}
// 
export const phoneDelete = (data) => {
    return request({
        method: 'post',
        url: '/phone/delete',
        data
    })
}
// 
export const phoneClearRecycleBin = (data) => {
    return request({
        method: 'post',
        url: '/phone/clear-recycleBin',
        data
    })
}
// 
export const phoneRoleIntent = (data) => {
    return request({
        method: 'post',
        url: '/phone/role-intent',
        data
    })
}
// 
export const phoneFindOneHistoryPhone = (data) => {
    return request({
        method: 'post',
        url: '/phone/findOne_historyPhone',
        data
    })
}
// 编辑
export const phoneUpdate = (data) => {
    return request({
        method: 'post',
        url: '/phone/update',
        data
    })
}
// 分配
export const phoneDistribute = (data) => {
    return request({
        method: 'post',
        url: '/phone/distribute',
        data
    })
}
// 
export const phoneImportPhone = (data) => {
    return request({
        method: 'post',
        url: '/phone/import-phone',
        data
    })
}
// 时间搜索
export const phoneCountAddStatus = (data) => {
    return request({
        method: 'post',
        url: '/phone/count-addStatus',
        data
    })
}
// 今周月查询
export const phoneTimeAll = (url, data) => {
    return request({
        method: 'post',
        url: '/phone/' + url,
        data
    })
}