<template>
  <el-card id="callMission">
    <header>
      <!-- <h1>呼叫任务</h1> -->
      <div class="search">
        <div class="left">
          <!-- <div class="left-wrap">
            <span>公司查员工</span>
            <el-input
              v-model="user_id"
              placeholder="请输入员工ID"
              size="small"
            ></el-input>
          </div> -->
          <div class="left-wrap">
            <span>任务名称</span>
            <el-input
              v-model="task_name"
              placeholder="请输入任务名称"
              size="small"
            ></el-input>
          </div>
          <!-- <div class="left-wrap">
            <span>请选择</span>
            <el-select
              v-model="task_type"
              placeholder="公司或部门"
              size="small"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="addCallMission"
            size="small"
            class="reset"
            >创建呼叫任务</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column
        ><el-table-column
          prop="user_id"
          label="用户ID"
          align="center"
        ></el-table-column
        ><el-table-column prop="task_type" label="任务类型" align="center">
          <template v-slot="scope">
            <div>
              {{ scope.row.task_type == 0 ? "公司" : "部门" }}
            </div>
          </template></el-table-column
        ><el-table-column
          prop="realname"
          label="归属人姓名"
          align="center"
        ></el-table-column
        ><el-table-column
          prop="task_name"
          label="任务名称"
          align="center"
        ></el-table-column>
        <el-table-column prop="create_time" label="任务创建时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.create_time) }}
            </div>
          </template></el-table-column
        ><el-table-column prop="task_time" label="任务结束时间" align="center">
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.task_time) }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          prop="yes_count"
          label="任务完成数"
          align="center"
        ></el-table-column
        ><el-table-column
          prop="no_count"
          label="任务未完成数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sum_count"
          label="任务总数"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="220" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="detailsPopup(scope)" size="small"
              >任务详情</el-button
            >
            <el-button type="success" @click="numUpload(scope)" size="small"
              >导入号码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>

    <!-- 任务详情弹出框 -->
    <el-dialog title="任务详情" :visible.sync="detailsVisible" width="30%">
      <el-table :data="taskData">
        <el-table-column
          property="activePhone"
          label="任务号码详情"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="100"
          align="center"
          fixed="right"
          v-if="roleName != '企业' || roleName != admin"
        >
          <template #default="scope">
            <el-button type="success" @click="call(scope)">拨打</el-button>
            <!-- <el-button type="primary" @click="update(scope)">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 编辑弹出框 -->
    <el-dialog
      :visible.sync="updateVisible"
      class="dblclick"
      :before-close="updateClose"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="编辑" name="update">
          <el-form ref="form" :model="form" label-width="100px" class="update">
            <el-form-item label="客户号码">
              <el-input
                v-model="form.phone"
                placeholder="请输入客户号码"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-input>
              <el-input
                v-model="form.activePhone"
                placeholder="请输入客户号码"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户姓名">
              <el-input
                v-model="form.customerPhone"
                placeholder="请输入客户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名称">
              <el-input
                v-model="form.company"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="决策人">
              <el-input
                v-model="form.choosePeople"
                placeholder="请输入决策人"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司规模">
              <el-input
                v-model="form.customerScale"
                placeholder="请输入公司规模"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户来源">
              <el-input
                v-model="form.customerSource"
                placeholder="请输入客户来源"
              ></el-input>
            </el-form-item>
            <el-form-item label="行业">
              <el-input
                v-model="form.industry"
                placeholder="请输入行业"
              ></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input
                v-model="form.address"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="update_status" style="line-height: 30px">
                <el-radio :label="0">一般客户</el-radio>
                <el-radio :label="1">意向客户</el-radio>
                <el-radio :label="2">无意向客户</el-radio>
                <el-radio :label="3">成交客户</el-radio>
                <el-radio :label="4">黑名单</el-radio>
                <el-radio :label="5">无状态</el-radio>
                <el-radio :label="6">未接通</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="跟进记录">
              <el-input
                type="textarea"
                v-model="form.historyRecord"
                placeholder="请输入跟进记录(备注)"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="updateSubmit" style="width: 100%"
            >确 定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="trailLog">
          <el-row v-show="historys">
            <el-col :span="24">
              <el-table :data="upRecords" style="width: 100%">
                <el-table-column prop="data" label="日期" width="">
                </el-table-column>
                <el-table-column prop="content" label="内容" width="">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- axb弹出框 -->
    <el-dialog
      title="回拨已拨打"
      :visible.sync="callVisible"
      width="30%"
      center
    >
      <span
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: red;
        "
        >请在20秒内用手机拨打:{{ xphone }}</span
      >
    </el-dialog>

    <!-- sip弹出框 -->
    <el-dialog
      title="SIP呼叫"
      :visible.sync="sipCallVisible"
      width="30%"
      :before-close="handleClose"
      @close="hang_up"
    >
      <sipCall
        :callPhoneNum="sipPhone"
        :callPhoneData="callData"
        @showValue="showMethod"
      ></sipCall>
    </el-dialog>

    <!-- 创建任务弹出框 -->
    <el-dialog title="创建任务" :visible.sync="addVisible" width="30%">
      <el-form ref="form" :model="addForm" label-width="100px">
        <el-form-item label="任务名称">
          <el-input
            v-model="addForm.taskName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务结束时间">
          <el-date-picker
            v-model="addForm.taskTime"
            type="datetime"
            placeholder="选择任务结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="addSubmit">确定</el-button>
      </template>
    </el-dialog>

    <!-- 号码导入弹出框 -->
    <el-dialog
      title="批量导入号码"
      :visible.sync="numShow"
      width="70%"
      class="num-upload"
    >
      <div class="left">
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          style="width: 100%"
          >点击下载模板</el-button
        >
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <el-card class="right">
        <el-form
          ref="form"
          :model="addForm"
          label-width="100px"
          style="display: flex"
        >
          <el-form-item label="任务名称">
            <el-input
              v-model="taskName"
              placeholder="请输入任务名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务结束时间">
            <el-date-picker
              v-model="taskTime"
              type="datetime"
              placeholder="选择任务结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <el-radio-group v-model="radioUserId" style="line-height: 30px">
          <el-radio :label="v.id" v-for="v in radioUserList" :key="v.id">
            {{ v.realname }}
          </el-radio>
        </el-radio-group>
      </el-card>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="numUploadSubmit" style="width: 100%"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import sipCall from "@/views/sip/sipCall.vue";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  phoneFindOneHistoryPhone,
  phoneUpdate,
  phoneFindUser,
} from "@/api/phone";
import { callAppBind } from "@/api/call";
import { phoneTaskList, phoneTaskAdd, phoneTaskUpdate } from "@/api/phone-task";
export default {
  components: { sipCall },
  data() {
    return {
      taskName: null,
      taskTime: null,
      radioUserId: null,
      radioUserList: [],
      file: null,
      numShow: false,
      addForm: {},
      addVisible: false,
      update_status: null,
      activeName: "update",
      sipPhone: null,
      admin: null,
      xphone: null,
      upRecords: [],
      belonger: [],
      drawer: false,
      form: {},
      options1: [
        {
          value: 0,
          label: "一般客户",
        },
        {
          value: 1,
          label: "意向客户",
        },
        {
          value: 2,
          label: "无意向客户",
        },
        {
          value: 3,
          label: "成交客户",
        },
        {
          value: 4,
          label: "黑名单",
        },
        {
          value: 5,
          label: "无状态",
        },
        {
          value: 6,
          label: "未接通",
        },
      ],
      updateVisible: false,
      callVisible: false,
      taskData: [],
      detailsVisible: false,
      options: [
        {
          value: 0,
          label: "公司任务",
        },
        {
          value: 1,
          label: "部门任务",
        },
      ],
      user_id: null,
      task_name: null,
      task_type: null,
      ms_username: null,
      roleName: null,
      historys: true,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.ms_username = localStorage.getItem("ms_username");
    this.getList();
  },
  methods: {
    // 导入号码确定
    numUploadSubmit() {
      let formDate = new FormData();
      // console.log("this.taskTime", this.taskTime);
      // console.log("this.taskName", this.taskName);
      // Message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("f", this.file);
      formDate.append("id", this.numId);
      formDate.append("userId", this.radioUserId);
      formDate.append(
        "taskTime",
        this.taskTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.taskTime)
          : null
      );
      formDate.append("taskName", this.taskName);
      let res = phoneTaskUpdate(formDate);
      res.then((resNum) => {
        console.log("导入号码返回参数", resNum);
        if (resNum.data.statusCode == "000000") {
          Message.success(resNum.data);
        } else {
          Message.error(resNum.data);
        }
        this.numShow = false;
        this.getList();
      });
    },
    // 导入号码
    numUpload(row) {
      this.numShow = true;
      this.taskTime = row.row.task_time;
      this.taskName = row.row.task_name;
      this.numId = row.row.id;
      let params = {};
      if (this.roleName != "人员") {
        let res = phoneFindUser(params);
        res.then((res) => {
          this.radioUserList = res.data.data.user;
        });
      } else {
        this.radioUserId = localStorage.getItem("id");
      }
    },
    //下载模板
    upload() {
      window.open("http://113.250.189.239:9005/phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
      console.log("上传的文件", file);
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 创建任务确定
    addSubmit() {
      let taskTime = this.getYMDHMS(
        "YYYY-mm-dd HH:MM:SS",
        this.addForm.taskTime
      );
      if (this.roleName == "企业") {
        this.taskType = 0;
      } else if (this.roleName == "组长") {
        this.taskType = 1;
      } else if (this.roleName == "人员") {
        this.taskType = 2;
      }
      if (this.roleName == "企业") {
        this.parentId = localStorage.getItem("id");
      } else {
        this.parentId = localStorage.getItem("parentId");
      }
      let params = {
        noUser: this.parentId,
        parentId: this.parentId,
        taskName: this.addForm.taskName,
        taskTime,
        taskType: this.taskType,
      };
      let res = phoneTaskAdd(params);
      res.then((res) => {
        let code = res.data.statusCode;
        if (code == "00000") {
          Message.success(res.data);
        } else {
          Message.error(res.data);
        }
        this.addVisible = false;
        this.getList();
      });
    },
    // 创建任务按钮
    addCallMission() {
      this.addVisible = true;
    },
    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // SIP关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    // 关闭历史记录时
    handleCloseRecord() {
      this.drawer = false;
      this.upRecords = [];
    },
    // 编辑关闭回调
    updateClose(done) {
      this.$confirm("点击编辑页的确定关闭弹出框")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    // 根进记录
    record() {
      let params = {
        phoneId: this.form.id,
      };
      let res = phoneFindOneHistoryPhone(params);
      res.then((res) => {
        let data = res.data.data;
        this.upRecords = [];

        if (data.historyRecord == "") {
          data.historyRecord.split("").forEach((v) => {
            this.upRecords.push(this.initArr(v));
          });
        } else {
          data.historyRecord.forEach((v) => {
            this.upRecords.push(this.initArr(v));
          });
        }
        this.drawer = true;
      });
    },
    // 将跟进历史数据格式化
    initArr(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -20),
      };
      return obj;
    },
    // 将归属人数据格式化
    initArr1(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -22),
      };
      return obj;
    },
    //编辑提交
    updateSubmit() {
      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          historyRecord: this.form.historyRecord,
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.updateVisible = false;
            this.getList();
          } else {
            Message.error(res.data.message);
            this.updateVisible = false;
            this.getList();
          }
        });
      }
    },
    //拨打按钮
    call(row) {
      // console.log("row", row);
      this.form.historyRecord = row.row.historyRecord;
      this.form.choosePeople = row.row.choosePeople;
      this.form.customerScale = row.row.customerScale;
      this.form.customerSource = row.row.customerSource;
      this.form.activePhone = row.row.activePhone;
      this.form.customerPhone = row.row.customerPhone;
      this.form.company = row.row.company;
      this.form.remarks = row.row.remarks;
      this.form.status = row.row.status;
      this.form.userId = row.row.userId;
      this.form.departId = row.row.departId;
      this.form.id = row.row.id;
      this.form.roleId = row.row.roleId;

      let parentId = JSON.parse(localStorage.getItem("parentId"));
      this.sipPhone = row.row.activePhone;
      let params = {
        passivePhone: this.sipPhone,
        activePhone: this.ms_username,
        parentId,
      };
      let res = callAppBind(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data);
          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
            this.call(row);
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          // Message.error(res.data);
          let data = res.data.data;
          if (data) {
            Message.error(JSON.parse(data).message);
          } else {
            Message.error(res.data.message);
          }
        }
        this.detailsVisible = false;
        this.updateVisible = true;
      });
    },
    //axb呼叫模式弹窗
    countTime() {
      if (this.times > 0) {
        setTimeout(() => {
          this.times -= 1;
          this.countTime();
        }, 1000);
      } else {
        this.callVisible = false;
      }
    },
    // 任务详情弹出框
    detailsPopup(scope) {
      // this.taskData = scope.row.no_phone.map((v, i) => {
      //   return { activePhone: v.activePhone };
      // });
      this.taskData = scope.row.no_phone;
      this.detailsVisible = true;
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.task_type = null;
      this.user_id = null;
      this.task_name = null;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        noUser: 1,
        size: this.size,
        taskType: this.task_type,
        userId: this.user_id,
        taskName: this.task_name,
      };
      let res = phoneTaskList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#callMission {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 35%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .dblclick /deep/.el-dialog__header {
    padding: 0;
  }
  .num-upload {
    /deep/ .el-dialog__body {
      display: flex;
      justify-content: space-between;
      .left {
        margin-right: 8px;
      }
      .right {
        flex: 1;
      }
    }
  }
}
</style>