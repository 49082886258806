/**
 * phone-task
 * 呼叫任务
 */

import request from '@/utils/request'

// 展示
export const phoneTaskList = (data) => {
    return request({
        method: 'post',
        url: '/phone-task/list',
        data
    })
}

// 创建呼叫任务
export const phoneTaskAdd = (data) => {
    return request({
        method: 'post',
        url: '/phone-task/add',
        data
    })
}

// 编辑叫任务
export const phoneTaskUpdate = (data) => {
    return request({
        method: 'post',
        url: '/phone-task/update',
        data
    })
}