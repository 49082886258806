<template>
  <el-card id="callMission">
    <header>
      <h1>呼叫任务</h1>
    </header>
    <el-divider></el-divider>
    <main>
      <el-tabs v-model="activeName">
        <el-tab-pane label="已分配号码任务" name="yet">
          <yetCall />
        </el-tab-pane>
        <el-tab-pane label="未分配号码任务" name="not">
          <notCall />
        </el-tab-pane>
      </el-tabs>
    </main>
  </el-card>
</template>

<script>
import yetCall from "@/views/callMission/yetCall.vue";
import notCall from "@/views/callMission/notCall.vue";

export default {
  components: { yetCall, notCall },
  data() {
    return {
      activeName: "yet",
    };
  },
};
</script>

<style lang="less" scoped>
#callMission {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 35%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
}
</style>